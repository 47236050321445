import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonAddComponent } from "./button-add/button-add.component";
import { InlineSVGModule } from "ng-inline-svg-2";
import { ButtonUploadComponent } from "./button-upload/button-upload.component";
import { AttachmentCardComponent } from "@app/shared/components/attachment-card/attachment-card.component";
import { ButtonEditComponent } from "./button-edit/button-edit.component";
import { ButtonAddUnderlineComponent } from "@app/shared/components/button/button-add-underline/button-add-underline.component";
import { RoamButtonComponent } from "@app/shared/components/button/roam-button/roam-button.component";
import { RoamLoaderModule } from "@app/shared/components/roam-loader/roam-loader.module";
import { RoamToggleSliderComponent } from "@app/shared/components/button/roam-toggle-slider/roam-toggle-slider.component";
import { ButtonDropdownComponent } from "@app/shared/components/button/button-dropdown/button-dropdown.component";
import { ButtonSaveExitComponent } from "@app/shared/components/button/button-save-exit/button-save-exit.component";
import { ButtonRoundedComponent } from "@app/shared/components/button/button-rounded/button-rounded.component";
import { ButtonIconComponent } from "@app/shared/components/button/button-icon/button-icon.component";
import { ButtonComponent } from "@app/shared/components/button/button/button.component";
import { ButtonEditDeleteComponent } from "@app/shared/components/button/button-edit-delete/button-edit-delete.component";
import { ButtonRadiusComponent } from "@app/shared/components/button/button-radius/button-radius.component";
import { ToggleCollapseComponent } from "@app/shared/components/button/toggle-collapse/toggle-collapse.component";
import { ButtonCancelComponent } from "@app/shared/components/button/button-cancel/button-cancel.component";
import { ButtonActionComponent } from "./button-action/button-action.component";
import { ButtonMatActionComponent } from "./button-action/button-mat-action.component";
import { ButtonManageComponent } from "./button-manage/button-manage.component";
import { ButtonMoreComponent } from "./button-more/button-more.component";
import { SelectButtonComponent } from "./select-button/select-button.component";

@NgModule({
  declarations: [ButtonUploadComponent, ButtonEditComponent],
  imports: [
    CommonModule,
    InlineSVGModule,
    AttachmentCardComponent,
    ButtonAddUnderlineComponent,
    RoamToggleSliderComponent,
    RoamButtonComponent,
    RoamLoaderModule,
    ButtonDropdownComponent,
    ButtonSaveExitComponent,
    ButtonRoundedComponent,
    ButtonIconComponent,
    ButtonComponent,
    ButtonEditDeleteComponent,
    ButtonRadiusComponent,
    ButtonCancelComponent,
    ToggleCollapseComponent,
    ButtonActionComponent,
    ButtonMatActionComponent,
    ButtonAddComponent,
    ButtonManageComponent,
    ButtonMoreComponent,
    SelectButtonComponent
  ],
  exports: [
    ButtonUploadComponent,
    AttachmentCardComponent,
    ButtonAddUnderlineComponent,
    ButtonEditComponent,
    RoamButtonComponent,
    RoamToggleSliderComponent,
    ButtonDropdownComponent,
    ButtonSaveExitComponent,
    ButtonRoundedComponent,
    ButtonIconComponent,
    ButtonComponent,
    ButtonEditDeleteComponent,
    ButtonRadiusComponent,
    ButtonCancelComponent,
    ToggleCollapseComponent,
    ButtonActionComponent,
    ButtonMatActionComponent,
    ButtonAddComponent,
    ButtonManageComponent,
    ButtonMoreComponent,
    SelectButtonComponent
  ],
})
export class ButtonModule {}
