import { HostListener, inject, Injectable, NgZone, signal } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { TableConfigModel } from "../components/table/model";

interface WindowSize {
  isTouchDevice: boolean;
}

@Injectable({
  providedIn: "root",
})
export class DeviceService {
  public showButton = signal<boolean>(false);
  public selected = signal<boolean | null>(null);
  private state = signal<boolean>(false);

  private triggerSubject = new BehaviorSubject<boolean>(false);
  toggleCheckbox$ = this.triggerSubject.asObservable();

  private resizeSubject = new BehaviorSubject<WindowSize>(this.getWindowSize());
  resize$ = this.resizeSubject.asObservable();

  protected ngZone = inject(NgZone);

  constructor() {
    this.init();
  }
  
  private init(): void {
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('resize', this.onResize.bind(this));
    });
  }

  private onResize(): void {
    const newSize = this.getWindowSize();
    this.resizeSubject.next(newSize);
  }

  private getWindowSize(): WindowSize {
    return {
      isTouchDevice: this.isTouchDevice(),
    };
  }

  isTouchDevice(): boolean {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0;
  }

  isTouchDeviceMediaQuery(): boolean {
    return window.matchMedia("(pointer: coarse)").matches;
  }

  triggerMethod() {
    const newState = !this.state();
    this.state.set(newState);

    this.triggerSubject.next(newState);
  }

  toggleSelect(table: TableConfigModel): void {
    table.dataSource.map(data => {
      data.isHover = !data.isHover;

      if (!this.state()) {
        data.isSelected = false;
      }
    });
  }

  tableStatus(isEmpty: boolean): void {
    this.showButton.set(!isEmpty);
  }

  public toggleCheckbox(table: TableConfigModel): void {
    this.toggleCheckbox$
        .subscribe(() => {
            this.toggleSelect(table);
        })
  }
}
