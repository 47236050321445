import { Component, computed, input, model, output } from "@angular/core";
import { RoamIconComponent } from "../../roam-icon/roam-icon.component";
import { MatMenuModule } from "@angular/material/menu";
import { actionConfig, ActionConfigKeys } from "../button-action/button-action.config";

@Component({
  selector: "button-more",
  standalone: true,
  imports: [RoamIconComponent, MatMenuModule],
  template: `
    <button
      style="min-width: 38px; padding: 10px"
      class="mr-0"
      [class]="asText() ? 'btn-base ' : 'btn align-center btn-o-' + btnBorder()"

      [matMenuTriggerFor]="menu">
      <roam-icon [name]="icon()" size="20" [color]="color()!" />
    </button>

    <mat-menu class="dropdown-content" #menu="matMenu">
      @if (!custom()) {
        @for (act of actions(); track $index) {
          <button mat-menu-item (click)="onAction.emit(act.id)">
            <div class="align-center gap-8">
              <roam-icon [name]="act.icon" size="18" />
              <span class="font-14px fw-600">
                {{ act.name }}
              </span>
            </div>
          </button>
        }
      }
      <ng-content></ng-content>
    </mat-menu>
  `,
  styles: ``,
})
export class ButtonMoreComponent {
  icon = input<string>("more-horizontal");
  label = input<string>();
  color = input<string>();
  btnBorder= input<string>('grey')
  type = model<ActionConfigKeys>("default");
  custom = input<boolean>();
  asText = input<boolean>();

  onAction = output<string>();

  actions = computed(() => {
    return actionConfig[this.type()];
  });
}
