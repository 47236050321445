import { NgClass } from "@angular/common";
import { Component, DestroyRef, HostListener, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { DeviceService } from "@app/shared/services/device.service";

@Component({
  selector: "select-button",
  standalone: true,
  imports: [NgClass],
  template: `
    @if (device.showButton() && device.isTouchDevice()) {
      <button
        class="btn flat"
        [ngClass]="device.selected() ? 'btn-grey' : 'btn-o-primary'"
        style="height: 36px"
        (click)="toggle()">
        Select
      </button>
    }
  `,
  styles: ``,
})
export class SelectButtonComponent {
  isSelected!: boolean;
  protected device = inject(DeviceService);
  protected destroyRef = inject(DestroyRef);

  @HostListener("window:resize", ["$event"])
  onResize(event: Event): void {
    this.device.resize$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    this.device.isTouchDevice()
  }

  toggle(): void {
    this.device.triggerMethod(); 
    this.device.selected.set(!this.device.selected());

    this.toggleSelectedTable();
  }

  toggleSelectedTable(): void {
    const table = document.querySelector('table');

    table?.classList.contains('table--selected') 
      ? table?.classList.remove('table--selected')
      : table?.classList.add('table--selected') 

  }
}
